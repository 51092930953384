export default {
	items: [
		{
			name: 'Dashboard',
			url: '/dashboard',
			icon: 'icon-home',
			badge: {
				variant: 'primary',
			},
		},
		{
			name: 'Account Setup',
			url: '/setup',
			icon: 'icon-settings',
			children: [
				{
					name: 'Company',
					url: '/company',
					icon: 'fa fa-building-o',
				},
				{
					name: 'Storage Location',
					url: '/location',
					icon: 'fa fa-map-marker',
				},
				{
					name: 'User',
					url: '/user',
					icon: 'fa fa-users',
				},
				{
					name: 'Connection',
					url: '/connection',
					icon: 'fa fa-link',
				},
				{
					name: 'Transportation',
					url: '/transportation',
					icon: 'fa fa-truck',
				},
				{
					name: 'Asset Type',
					url: '/asset-type',
					icon: 'fa fa-tags',
				},
			],
		},
		{
			name: 'Transactions',
			url: '/transaction',
			icon: 'icon-list',
			children: [
				{
					name: 'Asset Pool Distribution',
					url: '/asset-pool-distribution',
					icon: 'fa fa-pie-chart',
				},
				{
					name: 'Inventory Session',
					url: '/inventory-session',
					icon: 'fa fa-cubes'
				},
				{
					name: 'Dispatch',
					url: '/dispatch',
					icon: 'fa fa-truck',
				},
				{
					name: 'Receipt',
					url: '/receipt',
					icon: 'fa fa-map-pin',
				},
				{
					name: 'Maintenance',
					url: '/maintenance',
					icon: 'fa fa-wrench',
				},
			],
		},
		{
			name: 'Billing',
			url: '/billing',
			icon: 'icon-credit-card',
			children: [
				{
					name: 'Client Account',
					url: '/client-account',
					icon: 'fa fa-address-book',
				},
				{
					name: 'Billing Report',
					url: '/billing-report',
					icon: 'fa fa-file-text',
				},
			],
		},
		{
			name: 'Reports',
			url: '/reports',
			icon: 'fa fa-line-chart',
			children: [
				{
					name: 'Asset Accountability',
					url: '/asset-accountability',
					icon: 'fa fa-list-alt',
				},
				{
					name: 'Assets with Truckers',
					url: '/assets-with-truckers',
					icon: 'fa fa-truck',
				},
				{
					name: 'Delayed Transfer Report',
					url: '/delayed-transfer-reports',
					icon: 'icon-clock',
				},
				{
					name: 'Discrepancy Report',
					url: '/discrepancy-reports',
					icon: 'fa fa-flag',
				},
				{
					name: 'Dispatch Alert Logs',
					url: '/dispatch-alerts',
					icon: 'fa fa-bell'
				},
				{
					name: 'Incident Report',
					url: '/incident-reports',
					icon: 'fa fa-warning'
				},
				{
					name: 'Inventory Summary',
					url: '/inventory-summary',
					icon: 'fa fa-table',
				}
			],
		},
		{
			name: 'Support',
			url: '/support',
			icon: 'fa fa-handshake-o',
			children: [
				{
					name: 'Transfer Company',
					url: '/transfer-company-requests',
					icon: 'fa fa-exchange',
				},
				{
					name: 'User Session Logs',
					url: '/user-session-logs',
					icon: 'fa fa-key'
				}
			],
		}
	],
};
